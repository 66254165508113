import { Link } from "gatsby";
import React, { Fragment, ReactNode } from "react";
import BaseComponent from "../../base/base-component";
import { Route } from "../../global/route-enum";
import SEO from "../../components/seo/seo";
import "./404.scss";
import ContainerBox from "../../components/container-box/container-box";
import SvgIcon from "../../components/svg-icon/svg-icon";
import { SvgIconPathCommand } from "../../components/svg-icon/svg-icon-enum";

export default class NotFoundPage extends BaseComponent {
  protected declareTranslateCollection(): string {
    return "notFound";
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <SEO pageTitle={this.translate("title")} />
        <ContainerBox className="c-section--notfound">
          <div className="c-notfound">
            <div className="c-logo">
              <Link
                to={Route.HOME}
                className="c-link c-link--logo"
                aria-label="State of Democracy"
              >
                <SvgIcon
                  width="208"
                  viewBox="0 0 208 44"
                  paths={[
                    {
                      command: SvgIconPathCommand.STATE_OF_DEMOCRACY,
                      fill: "#fff",
                    },
                    {
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                      command: SvgIconPathCommand.LOGO_EAST,
                      fill: "#EDE6FA",
                    },
                    {
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                      command: SvgIconPathCommand.LOGO_WEST,
                      fill: "#8F60E1",
                    },
                  ]}
                />
              </Link>
            </div>
            <div className="c-notfound__wrap">
              <h1 className="c-notfound__title">
                {this.translate("notFound")}
              </h1>
              <p className="c-notfound__text">
                {this.translate("sorry")}, {this.translate("excuseText")}
              </p>
              <Link to={Route.HOME} className="c-btn is-white-bg">
                {this.translate("homeButtonText")}
                <SvgIcon
                  width="18"
                  viewBox="0 0 18 12"
                  paths={[
                    {
                      command: SvgIconPathCommand.M11_ARROW_RIGHT,
                      fill: "#0D0C0D",
                    },
                  ]}
                />
              </Link>
            </div>
          </div>
        </ContainerBox>
      </Fragment>
    );
  }
}
